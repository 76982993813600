import * as Sentry from "@sentry/nextjs";
import { motion, AnimatePresence } from "framer-motion";
import Image from "next/image";
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import DimensionsIcon from "assets/svgs/dimensionsIcon.svg";
import ElementIcon from "assets/svgs/element.svg";
import Remove from "assets/svgs/remove.svg";
import TimeIcon from "assets/svgs/time.svg";
import ElementsModal from "components/common/modals/elementsModal";
import useDebounce from "helpers/debounce";
import { getscoreIdFromKnacks, getTemplateGames } from "helpers/main";
import notification from "helpers/notification";
import { apiFacingGames } from "mock/games";
import { setPrismObj, setSelectedKnacks } from "redux/prism/prismSlice";
import { useGetRequiredGamesMutation } from "services/api/baseApiSlice";

export function TemplatesContent({
  clearBoard,
  clear,
  activeBoard,
  activeStep,
  canProceed,
}) {
  const dispatch = useDispatch();
  const [showElementModal, setShowElementModal] = useState(false);
  const [activeElement, setActiveElement] = useState(null);
  // get selectedKnacks
  const selectedKnacks = useSelector((state) => state.prism.selectedKnacks);

  const showElementsModal = (val) => {
    setActiveElement(val);
    setShowElementModal(true);
  };

  const clearBoardBtnAct = () => {
    dispatch(setSelectedKnacks([]));
    clearBoard();
  };

  const userInfo = useSelector((state) => state.auth.userInfo);

  const prismObj = useSelector((state) => state.prism.prismObj);
  const selectedKnacksTemplate = useSelector(
    (state) => state.prism.selectedKnacksTemplate
  );
  const [getRequiredGames, { isLoading }] = useGetRequiredGamesMutation();

  const debouncedValue = useDebounce(selectedKnacks, 1000);

  const [requireGames, setRequireGames] = useState([]);
  const [totalTime, setTotalTime] = useState(30);

  const getGamesForKnacks = async () => {
    const scoreIds =
      prismObj?.customTemplate?.length > 0
        ? getscoreIdFromKnacks(selectedKnacks)
        : getscoreIdFromKnacks(selectedKnacksTemplate ?? []);
    const body = {
      scoreIds,
    };
    if (scoreIds.length === 0) return;

    setPrismObj({
      ...prismObj,
      requiredGames: [3, 4, 5],
    });
    canProceed(true);
    return;

    // eslint-disable-next-line no-unreachable
    await getRequiredGames({ body })
      .unwrap()
      .then((payload) => {
        setRequireGames(payload.requiredGames);
        dispatch(
          setPrismObj({
            ...prismObj,
            requiredGames: payload.requiredGames,
          })
        );
        canProceed(true);
      })
      .catch((error) => {
        notification({
          status: "error",
          message: error?.message ?? error?.error,
        });
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    if (debouncedValue.length > 0) {
      getGamesForKnacks();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (selectedKnacks) {
      canProceed(false);
    }
  }, [selectedKnacks]);

  useEffect(() => {
    // game ids - requireGames
    // game objects - apiFacingGames
    // get time for each game from apiFacingGames
    // add up all times

    if (requireGames && requireGames.length) {
      let total = 0;
      apiFacingGames.forEach((game) => {
        if (requireGames.includes(game.id)) {
          total += game.duration;
        }
      });
      setTotalTime(total);
    }
  }, [requireGames]);

  return (
    <div className={`static flex h-full w-full flex-col gap-2 pb-[7rem]`}>
      <h2 className="h-[40px] w-[80%] text-[22px] font-medium text-[#191919]">
        {activeBoard
          ? `Dimensions from ${activeBoard.title}`
          : `Selected Dimensions`}
      </h2>

      <div
        className={`no-scrollbar relative h-full w-full
       overflow-y-auto rounded-[10px] bg-[#f1f1f1] p-4 pb-16 pr-2 pt-2`}
      >
        {/* required prism games */}
        <div
          className={`flex items-center justify-between rounded-t-[10px] px-6 pl-0 ${
            activeBoard?.elements?.length > 0 ? "h-[80px]" : "h-[40px]"
          } z-40 items-center`}
        >
          {((activeStep !== "templates" && selectedKnacks.length > 0) ||
            (activeStep === "templates" &&
              activeBoard &&
              activeBoard?.elements.length > 0)) && (
            <>
              <button
                onClick={() => clearBoardBtnAct()}
                className="mr-4 rounded-[10px] border-[1px] border-gray-600 px-6 py-1 text-[16px] font-semibold text-gray-600"
              >
                Clear
              </button>

              <div className="relative flex w-[200px] items-center gap-2">
                <Image
                  src={TimeIcon}
                  height="20"
                  width="20"
                  alt="Time required"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
                <p className="absolute left-[30px] flex items-center text-[12px] font-medium text-gray-600">
                  Total game time:
                  <span className="ml-2 flex items-center transition-all duration-300 ease-in">
                    {isLoading ? (
                      <svg
                        className="h-5 w-5 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="transparent"
                          strokeWidth="4"
                        >
                        </circle>
                        <path
                          className=""
                          fill="#191919"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        >
                        </path>
                      </svg>
                    ) : (
                      `${totalTime} mins`
                    )}
                  </span>
                </p>
              </div>
              <div className="relative flex w-[120px] items-center gap-2">
                <Image
                  src={DimensionsIcon}
                  height="20"
                  width="20"
                  alt="Time required"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
                <span className="absolute left-[30px] text-[12px] font-medium text-gray-600">
                  Dimensions: {selectedKnacks?.length ?? 0}
                </span>
              </div>
            </>
          )}
        </div>
        {/* step 1 knacks */}
        {activeStep !== "templates" && (
          <AnimatePresence>
            {selectedKnacks?.length > 0 && (
              <div className="mt-4 flex flex-wrap items-center justify-center gap-6 py-4 pt-[3rem] transition-all duration-300 ease-in">
                <AnimatePresence>
                  {selectedKnacks
                    ?.slice(0)
                    .reverse()
                    .map((element) => (
                      <OneElement key={element?._id} element={element} />
                    ))}
                </AnimatePresence>
              </div>
            )}

            {selectedKnacks?.length === 0 && (
              <div
                className={`absolute top-[50%] ${
                  userInfo?.subscriptionPlan === "free"
                    ? "right-1/2 translate-x-1/2"
                    : "right-1/2 translate-x-1/2"
                } flex w-[500px] items-center justify-center gap-6 px-2 py-4 text-center`}
              >
                <motion.h1
                  initial={{ opacity: 0.5, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, ease: "easeIn" }}
                  className="w-full text-[22px] font-medium text-gray-600"
                >
                  Click to select dimensions from any category
                </motion.h1>
              </div>
            )}
          </AnimatePresence>
        )}

        {/* step 0 template */}
        {activeStep === "templates" && (
          <AnimatePresence>
            {activeBoard && !clear && (
              <div
                className={`flex flex-wrap gap-6  py-4   ${
                  getTemplateGames(activeBoard).length > 0
                    ? "mt-4 py-4 pt-[3rem]"
                    : "mt-0 pt-0"
                } flex ${
                  activeBoard?.elements.length === 0 && "min-h-[250px]"
                }  items-center justify-center transition-all duration-300 ease-in`}
              >
                {activeBoard?.elements?.map((element, index) => (
                  <motion.div
                    key={element._id}
                    initial={{ scale: 0.9, y: 10, opacity: 0.5 }}
                    animate={{ scale: 1, opacity: 1, y: 0 }}
                    exit={{ y: -20, opacity: 0, transition: { duration: 0.5 } }}
                    transition={{
                      duration: 0.3,
                      ease: "easeIn",
                      delay: 0.1 + index * 0.025,
                    }}
                    className="relative flex flex-col items-center gap-2"
                    onClick={() => showElementsModal(element)}
                  >
                    <div
                      className={`relative h-[120px] w-[120px] rounded-full`}
                    >
                      <Image
                        src={
                          "https://res.cloudinary.com/knackapp/image/upload/v1667745050/static/brand/5e7a13c665801ff470196de1b6d800de.png"
                        }
                        fill
                        className="rounded-full object-cover object-center"
                        alt={element.name}
                      />
                    </div>
                    <BsInfoCircleFill
                      onClick={() => showElementsModal(element)}
                      className="absolute right-2 top-1 rounded-full bg-white text-[#6487FF]"
                      size={22}
                    />
                    <span className="text-[#A6A6A6]">{element.name}</span>
                  </motion.div>
                ))}
                {activeBoard?.elements.length === 0 && (
                  <motion.h1
                    initial={{ opacity: 0.5, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, ease: "easeIn" }}
                    className="w-full text-center text-[18px] font-medium text-[#B7D4FF]"
                    key={activeBoard?._id}
                  >
                    This template does not have any dimensions yet.
                  </motion.h1>
                )}
              </div>
            )}

            {activeBoard === null && (
              <div className="absolute top-[50%] flex w-full items-center justify-center gap-6 py-4 text-center">
                <motion.h1
                  initial={{ opacity: 0.5, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, ease: "easeIn" }}
                  className="text-center text-[18px] font-medium text-[#B7D4FF]"
                >
                  Templates are coming soon!
                </motion.h1>
              </div>
            )}
          </AnimatePresence>
        )}
      </div>

      <AnimatePresence>
        {showElementModal && (
          <ElementsModal
            element={activeElement.name}
            texts={activeElement.definition}
            close={() => setShowElementModal(false)}
            iconSvg={ElementIcon}
            useFixed
          />
        )}
      </AnimatePresence>
    </div>
  );
}

TemplatesContent.propTypes = {
  clearBoard: propTypes.func.isRequired,
  clear: propTypes.bool,
  activeBoard: propTypes.object,
  newElement: propTypes.object,
  removeElements: propTypes.bool,
  rerender: propTypes.func.isRequired,
  canProceed: propTypes.func.isRequired,
  activeStep: propTypes.number.isRequired,
  removeFromBoard: propTypes.object,
  resetRemoveBoard: propTypes.func.isRequired,
  selectedElements: propTypes.array.isRequired,
  removeElement: propTypes.func.isRequired,
  fromPlayGround: propTypes.bool,
};

export function OneElement({ element, showElementsModal, fromDimensions }) {
  const dispatch = useDispatch();
  const selectedKnacks = useSelector((state) => state.prism.selectedKnacks);
  return (
    <motion.div
      initial={{ scale: 0.9, y: 10, opacity: 0.5 }}
      animate={{ scale: 1, opacity: 1, y: 0 }}
      transition={{ duration: 0.3, ease: "easeIn" }}
      exit={{
        y: 20,
        opacity: 0,
        transition: { duration: 0.5 },
        scale: 0,
      }}
      key={element?._id}
      className="relative flex flex-col items-center gap-2"
      onClick={() => {
        // eslint-disable-next-line no-unused-expressions
        fromDimensions ? showElementsModal(element) : null;
      }}
    >
      <div className={`relative h-[100px] w-[100px] rounded-full`}>
        <Image
          src={
            "https://res.cloudinary.com/knackapp/image/upload/v1667038545/static/brand/gn3abyiodznaabywnmbv.png"
          }
          fill
          className="rounded-full object-cover object-center"
          alt={element.name}
        />
      </div>

      <span
        className={`text-xs font-medium ${
          fromDimensions ? "text-[#585858]" : "text-[#191919]"
        }`}
      >
        {element?.name}
      </span>
      {!fromDimensions && (
        <div
          className="absolute right-1 top-1"
          onClick={() =>
            dispatch(
              setSelectedKnacks(
                selectedKnacks.filter((el) => el._id !== element?._id)
              )
            )
          }
        >
          <Image
            src={Remove}
            width="25"
            height="25"
            alt="Remove dimension"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      )}
    </motion.div>
  );
}

OneElement.propTypes = {
  element: propTypes.object.isRequired,
  showElementsModal: propTypes.func,
  fromDimensions: propTypes.bool,
};
